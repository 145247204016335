<template>
    <div class="my-2">
        <div class="d-flex align-center">
            <v-icon large class="mr-2">mdi-attachment</v-icon>
            <media-library-attachment
                ref="media"
                :initial-value="model"
                name="applications"
                vapor
                :sortable="false"
                route-prefix="applications"
                :validation-rules="{ maxSizeInKB }"
                :upload-domain="uploadDomain"
                :vapor-signed-storage-url="vaporSignedStorageUrl"
                @is-ready-to-submit-change="$emit('readyChange', $event)"
                :after-upload="afterUpload"
                @change="onChange($event)"
            >
                <template slot="properties" slot-scope="{ object }">
                    <div class="media-library-property">
                        <span
                            class="d-inline-block text-truncate mb-n1"
                            style="max-width: 195px"
                            >{{ object.attributes.name }}</span
                        >
                        <div
                            v-if="object.attributes.size"
                            class="media-library-property"
                        >
                            {{ (object.attributes.size / 1024).toFixed(2) }} KB
                        </div>
                    </div>
                </template>
            </media-library-attachment>
            <div v-if="success">
                <v-tooltip top color="secondary" dark>
                    <template v-slot:activator="{ on, attrs }">
                        <a :href="download_url" target="_blank">
                            <v-avatar
                                size="48"
                                color="primary"
                                class="ml-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon large color="white"
                                    >mdi-file-download</v-icon
                                >
                            </v-avatar>
                        </a>
                    </template>
                    <span>Download {{ download_name }}</span>
                </v-tooltip>
            </div>
        </div>
        <div class="ml-11" style="height: 20px">
            <p v-if="error" class="error--text">
                {{ error }}
            </p>
            <p v-if="hint && !error" class="text-caption">
                {{ hint }}
            </p>
        </div>
    </div>
</template>
<script>
import { MediaLibraryAttachment } from "@spatie/media-library-pro-vue2-attachment";
import "@spatie/media-library-pro-styles";
export default {
    name: "psi-file-upload",
    components: {
        MediaLibraryAttachment,
    },
    props: {
        error: {
            type: String,
            required: false,
            default: "",
        },
        value: {
            type: Object,
            required: false,
            default: () => {},
        },
        name: {
            type: String,
            required: false,
            default: "applications",
        },
        maxSizeInKB: {
            type: Number,
            required: false,
            default: 20480,
        },
        uploadDomain: {
            type: String,
            required: false,
            default: process.env.VUE_APP_UPLOAD_DOMAIN,
        },
        vaporSignedStorageUrl: {
            type: String,
            required: false,
            default: process.env.VUE_APP_VAPOR_SIGNED_STORAGE_URL,
        },
        hint: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            success: false,
            model: this.value,
            download_url: "",
            download_name: "",
        };
    },
    computed: {},
    methods: {
        afterUpload(event) {
            this.success = event.success;
            if (this.success) {
                this.$emit("update:error", "");
            }
            this.download_url = this.model[event.uuid].original_url;
            this.download_name = this.model[event.uuid].name;

            this.$emit("afterUpload", { ...event, ...this.model });
        },
        onChange(media) {
            if (Object.keys(media).length === 0) {
                this.success = false;
            }
            this.model = media;
            this.$emit("input", this.model);
        },
        hasMedia() {
            // use the media state to determine if the array is empty
            return this.$refs.media.mediaLibrary.state.media.length > 0;
        },
    },
};
</script>

<style scoped>
</style>