var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-2" }, [
    _c(
      "div",
      { staticClass: "d-flex align-center" },
      [
        _c("v-icon", { staticClass: "mr-2", attrs: { large: "" } }, [
          _vm._v("mdi-attachment")
        ]),
        _c("media-library-attachment", {
          ref: "media",
          attrs: {
            "initial-value": _vm.model,
            name: "applications",
            vapor: "",
            sortable: false,
            "route-prefix": "applications",
            "validation-rules": { maxSizeInKB: _vm.maxSizeInKB },
            "upload-domain": _vm.uploadDomain,
            "vapor-signed-storage-url": _vm.vaporSignedStorageUrl,
            "after-upload": _vm.afterUpload
          },
          on: {
            "is-ready-to-submit-change": function($event) {
              return _vm.$emit("readyChange", $event)
            },
            change: function($event) {
              return _vm.onChange($event)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "properties",
              fn: function(ref) {
                var object = ref.object
                return [
                  _c("div", { staticClass: "media-library-property" }, [
                    _c(
                      "span",
                      {
                        staticClass: "d-inline-block text-truncate mb-n1",
                        staticStyle: { "max-width": "195px" }
                      },
                      [_vm._v(_vm._s(object.attributes.name))]
                    ),
                    object.attributes.size
                      ? _c("div", { staticClass: "media-library-property" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                (object.attributes.size / 1024).toFixed(2)
                              ) +
                              " KB "
                          )
                        ])
                      : _vm._e()
                  ])
                ]
              }
            }
          ])
        }),
        _vm.success
          ? _c(
              "div",
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "", color: "secondary", dark: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.download_url,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _c(
                                    "v-avatar",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "ml-2",
                                          attrs: {
                                            size: "48",
                                            color: "primary"
                                          }
                                        },
                                        "v-avatar",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { large: "", color: "white" }
                                        },
                                        [_vm._v("mdi-file-download")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      514169168
                    )
                  },
                  [
                    _c("span", [
                      _vm._v("Download " + _vm._s(_vm.download_name))
                    ])
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "ml-11", staticStyle: { height: "20px" } }, [
      _vm.error
        ? _c("p", { staticClass: "error--text" }, [
            _vm._v(" " + _vm._s(_vm.error) + " ")
          ])
        : _vm._e(),
      _vm.hint && !_vm.error
        ? _c("p", { staticClass: "text-caption" }, [
            _vm._v(" " + _vm._s(_vm.hint) + " ")
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }